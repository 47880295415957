<template>
  <div id="app1" class="app-container">
    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
      <el-menu-item :index="0">全部</el-menu-item>
      <el-menu-item :index="1">未分配</el-menu-item>
      <el-menu-item :index="2">已分配</el-menu-item>
      <div class="fr rightnav flex alignCenter">
        <el-button class="newbtn fr" plain @click="dialogAdd">新建</el-button>
        <el-button class="batchbtn fr" plain @click="openDialog">批量新增</el-button>
      </div>
    </el-menu>
    <div class="session1 clearfix">
      <div class="fl">
        <el-input
          placeholder="请输入探针编号、原设备ID、MAC-ID进行检索"
          v-model="keyword"
          class="input-with-select"
          @change="getseqList()"
        >
          <el-button class="searchbtn" slot="append" icon="el-icon-search" @click="getseqList()"></el-button>
        </el-input>
      </div>
      <div style="position: relative" ref="customDialog" class="fr">
        <a class="downloadstyle bord" :href="downloadurl"><i class="fa fa-download fhd"></i></a>
      </div>
    </div>
    <div class="integratable">
      <el-table
        :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)"
        row-key="id"
        @sort-change="sortChange"
        style="width: 100%"
      >
        <el-table-column prop="probecode" sortable="custom" align="right" label="探针编号"> </el-table-column>
        <el-table-column prop="deviceid" sortable="custom" show-overflow-tooltip align="right" label="原设备ID">
        </el-table-column>
        <el-table-column prop="macid" sortable="custom" align="right" label="MAC-ID"> </el-table-column>
        <el-table-column prop="createtime" sortable="custom" align="right" label="新增日期"> </el-table-column>
        <el-table-column prop="allocationtime" sortable="custom" align="right" label="分配日期"> </el-table-column>
        <el-table-column prop="groupname" sortable="custom" align="left" label="分配商户名称"> </el-table-column>
        <el-table-column prop="usingType" sortable="custom" align="left" label="探针类型">
          <template slot-scope="scope">
            {{ scope.row.usingType === 'count' ? '新零售' : '红外' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" min-width="100">
          <template slot-scope="scope">
            <el-button @click="dialogRest(scope.row, scope.$index)" type="text" size="small">设置</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flexend">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 30, 50]"
        :page-size="100"
        layout="total,sizes, prev, pager, next"
        :total="tableData.length"
      >
      </el-pagination>
    </div>
    <!--新增探针-->
    <el-dialog
      :title="dialogtitle"
      custom-class="addDialog"
      :visible.sync="dialogFormVisible"
      :before-close="cancelAddCamera"
    >
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <el-form-item label="原设备ID：" :label-width="formLabelWidth" prop="BAKID">
          <el-input v-model="form.BAKID" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="MAC-ID：" :label-width="formLabelWidth" prop="MACID">
          <el-input v-model="form.MACID" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-show="type === 'rest'" label="分配给：" :label-width="formLabelWidth" prop="group">
          <el-select v-model="form.group" value-key="cid" placeholder="请选择">
            <el-option v-for="item in groupOption" :key="item.cid" :label="item.cname" :value="item.cid"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="探针类型：" :label-width="formLabelWidth">
          <el-radio-group v-model="form.usingType">
            <el-radio label="count">新零售</el-radio>
            <el-radio label="detect">红外</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelAddCamera">取 消</el-button>
        <el-button type="primary" @click="confrim">提交</el-button>
      </div>
    </el-dialog>
    <!--批量新增导入-->
    <el-dialog title="导入探针" custom-class="importDialog" :visible.sync="importDialog">
      <div class="userList flex alignCenter item">
        <div class="le1">导入文件：</div>
        <div class="elDiv">
          <input
            readonly
            :class="fromErr.fileListModel && fileListModel == '' ? 'borderRed' : ''"
            v-model="fileListModel"
            class="phoneNumInput fileInput"
            placeholder="点击浏览按钮导入文件"
            type="text"
            name="file"
          />
        </div>
        <el-upload
          class="upload-demo"
          ref="upload"
          accept="application/vnd.ms-excel"
          :action="upUrl"
          :headers="headerData"
          with-credentials="true"
          :before-upload="beforeUpload"
          :on-success="sureBack"
          :on-error="errBack"
          :on-progress="upCenter"
          :show-file-list="false"
          :on-change="handleChange"
          :auto-upload="false"
        >
          <el-button slot="trigger" size="small">浏览</el-button>
        </el-upload>
      </div>
      <p v-show="fromErr.fileListModel && fileListModel == ''" class="errorShow mt5">文件不能为空！</p>
      <p v-show="filetype" class="errorShow mt5">文件类型必须是 .xls！</p>
      <div slot="footer" class="dialog-footer">
        <a :href="upDataUrl" target="_blank" class="loadbtn fl">下载导入模板</a>
        <el-button @click="importDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitUpload()">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  probeIsseqidExit,
  probeIsmacidExit,
  getprobedevinfo,
  setprobedevinfo,
  getCusList,
  getprobedevlist,
  addprobe,
} from 'src/api/legacy'

var storeHttp = '/newretail'
var httpUrl = '/newretail'

export default {
  data() {
    let that = this
    var checkBAKID = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入'))
      } else {
        let promise = new Promise(function (resolve, reject) {
          var checkparam = {}
          if (that.type === 'add') {
            checkparam = {
              devid: value,
            }
          } else {
            checkparam = {
              devid: value,
              id: that.id,
            }
          }
          probeIsseqidExit(checkparam).then((res) => {
            if (res && res.status === 0) {
              resolve(res.data)
            }
          })
        })
        promise.then((r) => {
          if (r) {
            callback(new Error('原设备ID已存在'))
          } else {
            callback()
          }
        })
      }
    }
    var checkMACID = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入'))
      } else {
        let promise = new Promise(function (resolve, reject) {
          var checkdata = {}
          if (that.type === 'add') {
            checkdata = {
              macid: value,
            }
          } else {
            checkdata = {
              macid: value,
              id: that.id,
            }
          }
          probeIsmacidExit(checkdata).then((res) => {
            if (res && res.status === 0) {
              resolve(res.data)
            }
          })
        })
        promise.then((r) => {
          if (r) {
            callback(new Error('原设备ID已存在'))
          } else {
            callback()
          }
        })
      }
    }
    return {
      activeIndex: 0,
      keyword: '',
      fonturl: httpUrl, //测试环境请求前缀
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      dialogFormVisible: false,
      importDialog: false,
      type: 'add',
      dialogtitle: '新增摄像头',
      form: {
        BAKID: '',
        group: '0',
        MACID: '',
        usingType: 'count',
      },
      id: '',
      groupOption: [],
      formLabelWidth: '120px',
      // 导入摄像头
      upUrl: '',
      fileListModel: '',
      upDataUrl: '',
      test: false,
      fromErr: {
        fileListModel: false,
      },
      filetype: false,
      parmes: {},
      order: '',
      prop: '',
      rules: {
        BAKID: [
          {
            validator: checkBAKID,
            trigger: 'blur',
          },
        ],
        MACID: [
          {
            validator: checkMACID,
            trigger: 'blur',
          },
        ],
      },
    }
  },
  methods: {
    // 新增探针
    addProbe(formName) {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let data = {
            macid: this.form.MACID,
            devid: this.form.BAKID,
            usingType: this.form.usingType,
          }
          addprobe(data).then((res) => {
            if (res && res.status === 0) {
              this.dialogFormVisible = false
              this.$message({
                message: '成功',
                type: 'success',
              })
              this.resetForm('ruleForm')
              this.getseqList()
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    cancelAddCamera() {
      this.resetForm('ruleForm')
      this.dialogFormVisible = false
    },
    confrim() {
      if (this.type === 'add') {
        this.addProbe('ruleForm')
      } else {
        this.updateSeq()
      }
    },
    dialogAdd() {
      this.type = 'add'
      this.dialogFormVisible = true
      this.dialogtitle = '新增探针'
      setTimeout(() => {
        this.resetForm('ruleForm')
      })
    },
    dialogRest(row) {
      this.type = 'rest'
      if (!row.id) return
      this.id = row.id
      this.getSeq()
      this.dialogFormVisible = true
      this.dialogtitle = '设置探针'
    },
    handleSelect(key, keyPath) {
      //menu切换
      this.activeIndex = key
      this.currentPage = 1
      this.getseqList()
    },
    getSeq() {
      //摄像头查询指定设备
      let data = {
        id: this.id,
      }
      getprobedevinfo(data).then((res) => {
        if (res && res.status === 0) {
          this.form.BAKID = res.data.deviceid
          this.form.MACID = res.data.macid
          this.form.group = res.data.cid
          this.form.usingType = res.data.usingType
        }
      })
    },
    updateSeq() {
      //设置探针
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let data = {
            id: this.id,
            devid: this.form.BAKID,
            macid: this.form.MACID,
            cid: this.form.group,
            usingType: this.form.usingType,
          }
          setprobedevinfo(data).then((res) => {
            if (res && res.status === 0) {
              this.dialogFormVisible = false
              this.$message({
                message: '成功',
                type: 'success',
              })
              this.getseqList()
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getGroup() {
      //设置摄像头分配的所有门店
      getCusList().then((res) => {
        if (res && res.status === 0) {
          this.groupOption = res.data
          this.groupOption.unshift({
            cname: '暂不分配',
            cid: 0,
          })
        }
      })
    },
    getseqList() {
      let data = {
        keyword: this.keyword,
        allocation: this.activeIndex,
      }
      const loading = this.$loading({
        text: '加载中',
        target: document.querySelector('.el-table__body'),
      })
      getprobedevlist(data).then((res) => {
        if (res && res.status === 0) {
          this.tableData = res.data.data
          this.currentPage = 1
          loading.close()
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    openDialog() {
      this.fileListModel = ''
      this.importDialog = true
      this.fromErr.fileListModel = false
      this.filetype = false
    },
    submitUpload() {
      if (!this.fileListModel) {
        this.fromErr.fileListModel = true
        return
      } else if (this.filetype) {
        return
      } else {
        this.fromErr.fileListModel = false
      }
      setTimeout(() => {
        this.$refs.upload.submit()
      })
    },
    beforeUpload(file) {
      console.log('~开始上传')
    },
    handleChange(file, fileList) {
      if (this.test) {
        this.fileListModel = ''
        this.test = false
        return
      }
      this.fileListModel = file.name
      if (file.raw.type == 'text/html') {
        this.filetype = true
      } else {
        this.filetype = false
      }
      if (fileList.length > 1) {
        fileList.splice(0, 1)
      }
    },
    upCenter(event, file, fileList) {
      this.proValue = file.percentage
    },
    sureBack(response, file, fileList) {
      this.test = true
      this.proValue = file.percentage
      if (response.status === 0) {
        if (response.data > 0) {
          this.fileListModel = ''
          this.importDialog = false
          this.$message({
            message: '上传成功',
            type: 'success',
          })
          this.getseqList()
        } else if (response.data === 0) {
          this.$message({
            message: '上传失败',
            type: 'warning',
          })
        } else {
          this.$alert(response.data.join('</br>'), '提示', {
            confirmButtonText: '确定',
            dangerouslyUseHTMLString: true,
          })
        }
        this.test = true
      } else {
        this.$message(response.msg)
      }
    },
    errBack(err, file, fileList) {
      this.test = true
    },
    overviewInit() {
      //页面初始化
      this.getGroup()
      // 上传
      this.upUrl = storeHttp + '/api/probe/uploadpreob'
      this.headerData = JSON.parse(localStorage.getItem('headerData'))
      this.upDataUrl = storeHttp + '/api/probe/downloadprobetemplate'
      this.getseqList()
    },
    sortChange(column) {
      const { prop, order } = column
      this.prop = prop
      this.order = order
    },
    sorter(a, b) {
      const aKey = a[this.prop]
      const bKey = b[this.prop]
      const order = this.order === 'ascending' ? 1 : -1
      return aKey > bKey ? order : -order
    },
  },
  watch: {},
  computed: {
    useTable() {
      if (this.order) {
        return this.tableData
          .slice()
          .sort(this.sorter)
          .slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
      } else {
        return this.tableData.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
      }
    },
    downloadurl() {
      return storeHttp + '/api/probe/downloadprobedevinfo?keyword=' + this.keyword + '&allocation=' + this.activeIndex
    },
  },
  created() {},
  mounted() {
    let ProbeStock = JSON.parse(sessionStorage.getItem('ProbeStock'))
    if (ProbeStock === null) {
      //缓存
      console.log('没有缓存')
    } else {
      this.activeIndex = ProbeStock.active
      this.keyword = ProbeStock.keyword
    }
    this.overviewInit()
    window.onbeforeunload = (e) => {
      //缓存
      let obj = {
        active: this.activeIndex,
        keyword: this.keyword,
      }
      sessionStorage.setItem('ProbeStock', JSON.stringify(obj))
    }
  },
}
</script>

<style lang="less" scoped>
.app-container {
  padding-top: 0px;
}
.session1 {
  padding: 20px 20px 0 20px;
  margin: 0 -20px;
  align-items: center;
  .fl {
    width: 400px;
  }
}
.shopBox {
  display: inline-block;
}
.titletop {
  width: 100%;
  padding: 20px 0px;
  border-bottom: 1px solid #e6e6e6;
}
.rightnav {
  height: 60px;
  line-height: 60px;
}
.el-button.newbtn {
  width: 60px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  padding: 0;
  color: #fff;
  background: #5c76cc;
  border-color: #5c76cc;
  border-radius: 2px;
  font-size: 12px;
}
.batchbtn {
  width: 80px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  padding: 0;
  background: #f4f5f7;
  border-radius: 2px;
  border-color: #d1d1d1;
  font-size: 12px;
  margin-left: 10px;
}
.el-select .el-input {
  width: 318px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
/* 按钮 */
.newbtn.el-button.is-plain:active {
  color: #fff;
  background: #5c76cc;
  border-color: #5c76cc;
  outline: 0;
}
.newbtn.el-button.is-active,
.newbtn.el-button.is-plain:active {
  color: #fff;
  background: #5c76cc;
  border-color: #5c76cc;
}
.newbtn.el-button.is-plain:focus,
.newbtn.el-button.is-plain:hover {
  color: #fff;
  background: #5c76cc;
  border-color: #5c76cc;
  width: 60px;
  height: 30px;
}
.newbtn.el-button:active {
  color: #fff;
  background: #5c76cc;
  border-color: #5c76cc;
  outline: 0;
}
.newbtn.el-button:focus,
.newbtn.el-button:hover {
  color: #fff;
  background: #5c76cc;
  border-color: #5c76cc;
  width: 60px;
  height: 30px;
}

.batchbtn.el-button.is-plain:active {
  color: #333;
  background: #f4f5f7;
  outline: 0;
}
.batchbtn.el-button.is-active,
.batchbtn.el-button.is-plain:active {
  color: #333;
  background: #f4f5f7;
}
.batchbtn.el-button.is-plain:focus,
.batchbtn.el-button.is-plain:hover {
  color: #333;
  background: #f4f5f7;
  border-color: #d1d1d1;
  width: 80px;
  height: 30px;
}
.batchbtn.el-button:active {
  color: #333;
  background: #f4f5f7;
  border-color: #d1d1d1;
  outline: 0;
}
.batchbtn.el-button:focus,
.batchbtn.el-button:hover {
  color: #333;
  background: #f4f5f7;
  border-color: #d1d1d1;
  width: 80px;
  height: 30px;
}

.flexend {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
}
.popupStyle {
  width: 420px;
  position: absolute;
  top: 33px;
  left: -298px;
  background-color: #ffffff;
  border-radius: 2px;
  z-index: 99;
}
.chooseProp {
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.trend-layout-block {
  position: relative;
  padding: 15px;
}
.trend-layout-block label input {
  margin-right: 7px;
}
.popupStyle label {
  width: 128px;
  margin-bottom: 15px;
}
.downloadstyle {
  width: 61px;
  height: 30px;
  position: relative;
  bottom: -1px;
  line-height: 27px;
  border-radius: 2px;
  text-align: center;
  color: #000;
  font-size: 14px;
  background-color: #f4f5f7;
  z-index: 1;
}
.add-indent label {
  width: 121px;
  height: 16px;
  overflow: hidden;
}

.el-select .el-input,
.el-select .el-input .el-input__inner,
.el-form-item__content .el-input .el-input__inner {
  width: 200px;
}
.el-select-dropdown.el-popper {
  min-width: 200px !important;
}
.addDialog .el-input__prefix,
.addDialog .el-input__suffix {
  top: 2px;
}

/* 导入摄像头 */
.userList div.le1 {
  font-size: 14px;
  color: #333;
  text-align: right;
  font-weight: bold;
  width: 90px;
  text-align: right;
}
.userList .elDiv {
  font-size: 12px;
  color: #333;
  margin-left: 20px;
}
.userList .elDiv > input {
  border: 1px solid #c9c9c9;
  border-radius: 2px;
  width: 210px;
  height: 30px;
  font-size: 12px;
  padding-left: 10px;
  margin-right: 10px;
}
.errorShow {
  font-size: 12px;
  color: #ff2600;
  letter-spacing: 0;
  line-height: 12px;
  padding-left: 110px;
}
.alertInfo {
  line-height: 30px;
  background: #f4f5f7;
  border-radius: 4px;
  opacity: 0.85;
  padding: 0 20px;
  color: #999;
}
.userList a {
  font-size: 12px;
  color: #5c76cc;
  cursor: pointer;
}

.importDialog .el-dialog__body {
  padding-bottom: 30px;
}

.loadbtn {
  height: 30px;
  line-height: 30px;
}

.el-input-group__append,
.el-input-group__prepend {
  padding: 0 4px;
}
.el-input-group > .el-input__inner {
  width: 350px;
}
</style>